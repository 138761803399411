import React from "react";
import { Container, Button } from "react-bootstrap";
import "./Hero.css";
import banner from "../../assets/palm.jpg";

const Hero = () => {
  return (
    <div className="hero">
      <img src={banner} className="banner" />
      <Container>
        <div className="banner-content">
          <span>
            Guru Kamal <br></br>
            Prasad Dahal
          </span>
        </div>
        <div className="banner-btn">
          <Button className="btn btn-lg contact-btn" href="tel:9810403776">
            Contact
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
