import React from "react";
import { Container } from "react-bootstrap";
import "./BtFooter.css";
import { BsHeartFill } from "react-icons/bs";
import logo from "../../assets/logo.png";

const BtFooter = () => {
  return (
    <>
      <div className="bt-footer-bar text-white p-5" id="contact">
        <Container>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col">
              <img src={logo} height="150" width="190" />
            </div>
            <div className="col">
              <p id="title">Pages</p>
              <p id="subtitle">
                <a href="#about">About Me</a>
              </p>
              <p id="subtitle">
                <a href="#services">Services</a>
              </p>
              <p id="subtitle">
                <a href="#projects">Projects</a>
              </p>
              <p id="subtitle">
                <a href="#location">Location</a>
              </p>
            </div>
            <div className="col-auto">
              <p id="title">Contact</p>
              <p id="sub-title">Guru Kamal Prasad Dahal</p>
              <p id="subtitle">Tarhara, Itahari</p>
              <p id="subtitle">kamaldahal2040@gmail.com</p>
              <p id="subtitle">9862058233, 9810403776</p>
            </div>
          </div>
        </Container>
      </div>
      <div className="bt-footer-bar-bottom p-2 text-white">
        <Container>
          <div className="row">
            <div className="col">
              <a
                className="designer-redirect"
                target="_blank"
                href="https://navrajkhanal.com.np"
              >
                Designed By : Navraj Khanal
              </a>
            </div>
            <div className="col-auto">
              <a
                className="designer-redirect"
                target="_blank"
                href="https://navrajkhanal.com.np"
              >
                &copy; Made with <BsHeartFill color="#e76f51" /> in Nepal
              </a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BtFooter;
