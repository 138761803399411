import magazine from "../assets/magazine.jpg";
import nepaltv from "../assets/nepaltv.jpg";
import radio from "../assets/radio.jpg";
import arinatv from "../assets/tv.jpg";
import jyoparishad from "../assets/jyoparishad.png";
import whm from "../assets/whm.jpg";

export const ProjectData = [
  {
    alt: "Nepal Tv",
    src: nepaltv,
    title: "Nepal Television",
    description: "Organized the most famous ",
  },
  {
    alt: "Dharma Sanskriti Sandesh",
    src: magazine,
    title: "Editorial Dharma Sanskriti Sandesh",
    description: "Monthly magazine on sanatan dharma",
  },
  {
    alt: "Saptakoshi Radio",
    src: radio,
    title: "Saptakoshi Radio",
    description: "Astrology Program Presenter",
  },
  {
    alt: "Jyotish Parishad",
    src: jyoparishad,
    title: "Jyotish Parishad",
    description: "Active Member of the Jyotish Parishad Nepal",
  },
  {
    alt: "Arina TV",
    src: arinatv,
    title: "Arina Television",
    description: "Chief organizer of the astrology program",
  },
  {
    alt: "WHM",
    src: whm,
    title: "World Hindu Committee",
    description: "Active Member",
  },
];
