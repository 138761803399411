import astrology from "../assets/astrology.jpg";
import vastu from "../assets/vastu.jpg";
import planets from "../assets/planets.jpeg";
import karmakanda from "../assets/karmakanda.jpg";
import marriage from "../assets/marriage.jpeg";
import match from "../assets/matching.jpg";

export const ServiceData = [
  {
    alt: "Astrology",
    src: astrology,
    title: "Astrology",
    description:
      "Find about your past,present and future along with the vedic remedies",
  },
  {
    alt: "Vastu",
    src: vastu,
    title: "Vastu Shastra",
    description:
      "The powerful vedic way to organize and place things inside home for vibrant energy",
  },
  {
    alt: "Planets",
    src: planets,
    title: "Planetary Defects Remedy",
    description:
      "The remedy for the accumulative planetary faults in the kundali with ancient vedic rituals",
  },
  {
    alt: "Karma Kanda",
    src: karmakanda,
    title: "Karma Kanda",
    description: "The powerful vedic ritual for day to day activities",
  },
  {
    alt: "Kundali Matching",
    src: match,
    title: "Kundali Matching",
    description: "Kundali matching with the ancient astrological technolgies",
  },
  {
    alt: "Marriage",
    src: marriage,
    title: "Marriage Ceremony",
    description:
      "The auspicious ceremony with the traditional sanatan dharma style",
  },
];
