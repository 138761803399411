import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "./Header.css";
import logo from "../../assets/logo.png";
import { AiFillFacebook, AiFillYoutube } from "react-icons/ai";
import { IoLogoTiktok } from "react-icons/io5";
import { Menu, Dropdown } from "antd";
import { LangTrans } from "../LangTrans";
import { SiGoogletranslate } from "react-icons/si";

const Header = () => {
  return (
    <Navbar bg="white" expand="lg" className="shadow-sm">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} height="50" width="60" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto" style={{ fontSize: 20 }}>
            <Nav.Link id="nav-link-text" href="#services">
              Services
            </Nav.Link>
            <Nav.Link id="nav-link-text" href="#projects">
              Projects
            </Nav.Link>
            <Nav.Link id="nav-link-text" href="#contact">
              Contact
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              id="social-media-link"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100001431377585"
              title="Facebook"
            >
              <AiFillFacebook size={20} />
            </Nav.Link>
            <Nav.Link
              id="social-media-link"
              target="_blank"
              href="https://tiktok.com"
              target="_blank"
              title="Tiktok"
            >
              <IoLogoTiktok size={20} />
            </Nav.Link>
            <Nav.Link
              id="social-media-link"
              target="_blank"
              href="https://www.youtube.com/watch?v=vN1MuBtmmfI"
              title="YouTube"
            >
              <AiFillYoutube size={20} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
