import About from "./components/About/About";
import { BtFooter } from "./components/BtFooter";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Map } from "./components/Map";
import { Projects } from "./components/Projects";
import { Services } from "./components/Services";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <Services />
      <Projects />
      <Map />
      <BtFooter />
    </div>
  );
}

export default App;
