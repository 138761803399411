import React from "react";
import { Card } from "antd";
import { Container } from "react-bootstrap";

const { Meta } = Card;

const DetailCard = ({ alt, src, title, description }) => {
  return (
    <Container>
      <div className="row mt-4">
        <Card
          hoverable
          style={{ width: 350 }}
          cover={<img alt={alt} src={src} height="250" />}
        >
          <Meta title={title} description={description} />
        </Card>
      </div>
    </Container>
  );
};

export default DetailCard;
