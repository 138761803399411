import React from "react";
import { Container } from "react-bootstrap";
import "./Projects.css";
import DetailCard from "../DetailCard/DetailCard";
import { ProjectData } from "../../data/ProjectData";

const Projects = () => {
  return (
    <div className="projects" id="projects">
      <Container>
        <span className="section-title">Projects</span>
        <div className="grid-container">
          {ProjectData.map((data, key) => {
            return (
              <DetailCard
                key={key}
                alt={data.alt}
                src={data.src}
                title={data.title}
                description={data.description}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Projects;
