import React from "react";
import { Container } from "react-bootstrap";
import "./About.css";
import profile from "../../assets/profile.jpeg";

const About = () => {
  return (
    <div className="about" id="about">
      <Container>
        <span className="section-title">About</span>
        <div className="grid-container">
          <div className="description">
            Worked in the astrology projects and fields for more than a decade,
            Guru Kamal Prasad Dahal has foreseen hundreds of Kundali and
            provided the astrological consulations. Apart from these he is an
            active member of the Worlds Hindu Committee and a Peace activist.
            With his ancient knowlwedge and expereinces so many people have
            benefitted and led to a happy and joyful life. He has worked on
            dozens of projects such as Nepal Television, Arina Television,
            Saptakoshi Radio Fm and many more.
          </div>
          <div className="image-content">
            <img src={profile} className="about-img" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
