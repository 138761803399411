import React from "react";
import { Container } from "react-bootstrap";
import "./Services.css";
import DetailCard from "../DetailCard/DetailCard";
import { ServiceData } from "../../data/ServiceData";

const Services = () => {
  return (
    <div className="services" id="services">
      <Container>
        <span className="section-title">Services</span>
        <div className="grid-container">
          {ServiceData.map((data, key) => {
            return (
              <DetailCard
                key={key}
                alt={data.alt}
                src={data.src}
                title={data.title}
                description={data.description}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Services;
